import React, {useEffect, useRef, useState, useMemo, useCallback} from 'react';
import Main from "../../modules/main/main";
import usePermissions from "../../services/auth/usePermissions";
import {useNavigate} from "react-router-dom";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {apiSettings} from "../../services/api/settings"; // Optional theme CSS
import DialogModule from "../../modules/dialog/dialog";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useForm} from "react-hook-form";
import Moment from "react-moment";

function Settings(props) {
    const validationSchema = Yup.object().shape({
        value: Yup.string().required('Please select a date.'),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    };
    const {
        register,
        handleSubmit,
        reset,
        formState,
        setValue
    } = useForm(formOptions);
    const { errors, isValid } = formState;
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [userCan] = usePermissions();
    const breadcrumbs = [
        {"link": "/", "name": "Settings", "active": true}
    ];

    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [rowData, setRowData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [editData, setEditData] = React.useState({id: null, name: "",value: '', created_at: null, updated_at: null});
    const [error, setError] = useState('');
    const handleOpen = (setting) => {
        setEditData(setting);
        setValue('value',setting.value)
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const [columnDefs] = useState([
        {
            field: 'name',
            headerName: 'Setting Name',
            cellRenderer: params => {
            // .split('_')
            //         .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
            //         .join(' ')
                return params.value;
            }
        },
        {
            field: 'value',
            headerName: 'Date',
            cellRenderer: params => {
                return (<Moment format={'MM/DD/yyyy'}>{params.value}</Moment>)
            }
        },
        {
            field: 'updated_at',
            headerName: 'Last Updated',
            cellRenderer: params => {
                return (<Moment format={'MM/DD/yyyy hh:mm:ss a'}>{params.value}</Moment>)
            }
        },
        {
            headerName: 'Action',
            cellRenderer: params => {
                if(userCan('edit.settings')) {
                    return (
                        <span>
                        <button className={'btn btn-primary mb-2'} onClick={() => handleOpen(params.data)}>Edit</button>
                    </span>
                    );
                }

            },
            editable: false,
            filter: false,
            minWidth: 100,
            maxWidth: 100,
        }
    ]);

    const defaultColDef = useMemo( ()=> ({
        sortable: true,
        resizable: true,
        filter: true,
        floatingFilter: true,
        suppressMenu: true
        // filter: 'agTextColumnFilter',
    }));

    let fetchSettings = React.useCallback(async ()=>{
        apiSettings.getAll().then(res=>{
            setRowData( res.settings.map((setting)=> {
                setting.name = setting.name.split('_')
                    .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
                    .join(' ');
                return setting;
            }));
            setLoading(false)
        })
    })

    const gridOptions = {
        // PROPERTIES
        // Objects like myRowData and myColDefs would be created in your application
        rowData: rowData,
        columnDefs: columnDefs,
        pagination: true,
        rowSelection: 'single',

        // EVENTS
        // Add event handlers
        // onRowClicked: event => console.log('A row was clicked'),
        onColumnResized: event => event.api.sizeColumnsToFit(),
        onGridReady: event => {
            event.api.sizeColumnsToFit();
            event.columnApi.resetColumnState();
        },

        // CALLBACKS
        getRowHeight: (params) => 25
    }

    function onSave(data) {
        setError('');
        // data.append('_method', "PUT");
        data = {...data, '_method': 'PUT'};
        apiSettings.patch(editData.id, data).then((res) => {
          if(res.status){
              setError(res.data);
          }
          else {
              handleClose();
              fetchSettings();
              reset();
              // navigate(`/users/${res.user.id}`);
          }
        });
        return false;
    }

    useEffect(() => {
        if(!userCan('view.settings')){
            navigate('/');
        }
        fetchSettings()
    }, [])

    return (
        <Main title="Settings" breadcrumbs={breadcrumbs} loading={loading}>
            <div className="ag-theme-alpine" style={{ height: 800 }}>
                <AgGridReact
                    style={{ width: '100%', height: '100%;' }}
                    ref={gridRef} // Ref for accessing Grid's API

                    rowData={rowData} // Row Data for Rows

                    // columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    gridOptions={gridOptions}
                    getRowHeight={1000}
                    // animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    // rowSelection='multiple' // Options - allows click selection of rows

                    // onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                ></AgGridReact>
            </div>

            <DialogModule
                open={open}
                onClose={handleClose}
                setOpen={setOpen}
                title="Edit Setting"
                size="md"
                fullWidth={true}
                hideButtons={true}
            >
                <form className="form-horizontal" onSubmit={handleSubmit(onSave)}>
                    {error && <div className="alert alert-danger">
                        <h5><i className="icon fas fa-ban"></i> Error!</h5>
                        {error}
                    </div>}
                    <div className="form-group row">
                        <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                        <div className="col-sm-10">
                            {editData.name}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="value" className="col-sm-2 col-form-label">Date</label>
                        <div className="col-sm-10">
                            <input
                                type="date"
                                name="value"
                                {...register('value')}
                                className={`form-control ${errors.value ? 'is-invalid' : ''}`}
                                placeholder=""
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="offset-sm-2 col-sm-10">
                            <button type="submit" className="btn btn-primary" disabled={!isValid}>Save</button>
                        </div>
                    </div>
                </form>
            </DialogModule>
        </Main>
    );
}

export default Settings;