import { useState, useEffect } from 'react';
import useToken from './useToken';

const useUser = () => {
    const [token] = useToken();

    const getPayloadFromToken = token => {
        const encodedPayload = token.split('.')[1];

        return JSON.parse(atob(encodedPayload))[0];
    }

    const [user, setUser] = useState(() => {
        if(!token) return null;
        return getPayloadFromToken(token)
    })

    useEffect(() =>{
        // console.log("I am updating user.")
        if(!token){
            setUser(null);
        }
        else {
            setUser(getPayloadFromToken(token));
        }
    }, [token]);

    return user;
}

export default useUser;