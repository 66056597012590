import {useParams, useLocation, useNavigate, Link} from "react-router-dom";
import { VerifyEmailApi } from "../../services/api/verifyEmail";
import React, {useEffect, useState} from "react";
import ReactLoading from "react-loading";
import {PasswordResetApi} from "../../services/api/password-reset";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useForm} from "react-hook-form";

function PasswordReset(props){
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(undefined);
    const [resetPasswordMessage, setResetPasswordMessage] = useState('');
    let location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token'); // bar
    const email = params.get('email'); // bar
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('Password is required'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        defaultValues: {
            token: token,
            email: email
        },
        mode: 'onChange'
    };
    const {register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isValid } = formState;
    // console.log(token)
    // console.log(email)
    // let token = reset[0];
    const navigate = useNavigate();
    // useEffect(() => {
    //     VerifyEmailApi.getSingle(url)
    //     .then(res => {
    //         if(res.status === 401){
    //             setMessage(res.data.message)
    //         }
    //         else{
    //             setMessage(res.message)
    //             localStorage.removeItem('token')
    //             localStorage.setItem('verified', 'true')
    //             navigate('/login')
    //         }
    //     });
    // }, [navigate, url])

    const onResetPasswordClick = (formValues) =>{
        // display form data on success
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
        // return false;
        //     setErrorMsg("Register is not implemented yet");
        setResetPasswordMessage(undefined)
        setErrorMsg(undefined)
        setLoading(true);

        // console.log(formValues)

        PasswordResetApi.post(formValues).then(res => {
            // console.log(res);
            // console.log(res.message);
            setLoading(false)
            if(res.status === 400){
                setErrorMsg(prev=> res.data.message)
            }
            // else{
            //     localStorage.setItem("token", res.access_token)
            //     setToken(res.access_token)
            // }
            else{
            localStorage.setItem('resetPassword', res.message);
            navigate('/login')
            // setRegisteredMsg(prev=> res.message)
            }
            // reset()
        });
    }


    return (
        <div className="login-page">
            <div className={errorMsg !== undefined ? "alert alert-danger alert-dismissible": "d-none"}>
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                <h5><i className="icon fas fa-check"></i> Error!</h5>
                {errorMsg}
            </div>
            <div className="login-box">
            <div className="card card-outline card-primary ">

                <div className={loading ? "overlay" : "overlay d-none"}>
                    <ReactLoading type="spin" color="black" width={'10%'} height={'10%'} />
                </div>

                <div className="card-header text-center">
                    <Link to={"/forgot-password"} className="h1">{process.env.REACT_APP_NAME}</Link>
                </div>
                <div className="card-body">
                    <p className="register-box-msg">You are only one step away from your new password, reset your password now.</p>
                    <form onSubmit={handleSubmit(onResetPasswordClick)}>
                        <div className="input-group mb-3">
                            <input type="password" name="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} placeholder="Password" autoComplete="off"/>
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock"/>
                                </div>
                            </div>
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>

                        <div className="input-group mb-3">
                            <input name="password_confirmation" type="password" placeholder="Retype Password" {...register('password_confirmation')} className={`form-control ${errors.password_confirmation ? 'is-invalid' : ''}`} autoComplete="off"/>
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock"/>
                                </div>
                            </div>
                            <div className="invalid-feedback">{errors.password_confirmation?.message}</div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <button disabled={!isValid} type={"submit"} className="btn btn-primary btn-block">Reset password</button>
                            </div>
                            {/*/.col*/}
                        </div>
                    </form>

                    <p className="text-center mt-3 mb-0">
                        <Link to={"/login"} className="text-center">Login</Link>
                    </p>
                </div>
                {/*/.card-body*/}
            </div>
            {/*/.card*/}
        </div>
        </div>
    )
}

export default PasswordReset;