import ApiCore from "../utilities/core";

const url = 'sfa/dashboard';
const plural = 'dashboard';
const single = 'dashboard';

// plural and single may be used for message logic if needed in the ApiCore class.

const DashboardApi = new ApiCore({
    post: true,
    getAll: true,
    url: url,
    plural: plural,
    single: single,
    requireToken: true
});

export default DashboardApi;
