import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import ReactLoading from 'react-loading';
import { LoginApi } from "../../services/api/login";
import useToken from "../../services/auth/useToken";
import useAuth from "../../services/auth/useAuth";
import useUser from "../../services/auth/useUser";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";

function Login(props) {
    let location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirect_url = queryParams.get('redirect_url');
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [verifiedMsg, setVerifiedMsg] = useState(undefined);
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        password: Yup.string()
            .required('Password is required')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        defaultValues: {
            // email: "qazi91@gmail.com",
            // password: "Test1234"
            }
    };
    const {register, formState, handleSubmit } = useForm(formOptions);
    const { errors } = formState;
    const [token, setToken] = useToken();
    let isAuth = useAuth();

    const user = useUser();
    const navigate = useNavigate();
    const onSignInClick = async (formValues) =>{
        setLoading(true);
        await LoginApi.post(formValues).then(res => {
            setLoading(false)
            // console.log(res)
            if(res.status === 401){
                setErrorMsg("Please check your login info and try again.")
            }
            else{
                setToken(res.access_token)
                if(res.verified === null){
                    navigate('/verify-account')
                }
                else{
                    if(redirect_url !== null){
                        // console.log("Redirect URL found")
                        // console.log(redirect_url)
                        return navigate(redirect_url)
                    }
                    else{
                        // console.log("I'm in else.")
                        // console.log("I am auth")
                        navigate('/')
                    }
                }
            }
        });
    }

    useEffect(() =>{
        if(localStorage.getItem('verified') !== null){
            setVerifiedMsg('Your email has been verified. Please login.');
            localStorage.removeItem('verified');
        }
        if(localStorage.getItem('registered') !== null){
            setVerifiedMsg(localStorage.getItem('registered'));
            localStorage.removeItem('registered');
        }
        if(localStorage.getItem('forgotPassword') !== null){
            setVerifiedMsg(localStorage.getItem('forgotPassword'));
            localStorage.removeItem('forgotPassword');
        }
    }, [])


    return (
        <div className="login-page">
            <div className={verifiedMsg !== undefined ? "alert alert-success alert-dismissible": "d-none"}>
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                <h5><i className="icon fas fa-check"></i> Success!</h5>
                {verifiedMsg}
            </div>
            <div className="login-box">
                {/*/.login-logo*/}
                <div className="card card-outline card-primary ">

                    <div className={loading ? "overlay" : "overlay d-none"}>
                        <ReactLoading type="spin" color="black" width={'10%'} height={'10%'} />
                    </div>

                    <div className="card-header text-center">
                        <Link to={"/login"} className="h1">{process.env.REACT_APP_NAME}</Link>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Sign in to start your session</p>
                        <div className="text-center">
                            <div className="mb-3 text-danger">
                                <strong>{errorMsg}</strong>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSignInClick)}>
                            <div className="input-group mb-3">
                                <input name="email" type="text" {...register('email')} placeholder="Email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" name="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} placeholder="Password" autoComplete="off" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"/>
                                    </div>
                                </div>
                                <div className="invalid-feedback">{errors.password?.message}</div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <div className="form-check">
                                        <input name="rememberMe" type="checkbox" {...register('rememberMe')} id="rememberMe" className={`form-check-input ${errors.rememberMe ? 'is-invalid' : ''}`} />
                                        <label htmlFor="rememberMe">Remember Me</label>
                                        <div className="invalid-feedback">{errors.rememberMe?.message}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                </div>
                            </div>
                        </form>

                        <p className="text-center mb-1">
                            <Link to={"/forgot-password"}>I forgot my password</Link>
                        </p>
                        {/*<p className="text-center mb-0">*/}
                        {/*    <Link to={"/register"} className="text-center">Register a new account</Link>*/}
                        {/*</p>*/}
                    </div>
                    {/*/.card-body*/}
                </div>
                {/*/.card*/}
            </div>
        </div>
    );
}

export default Login;