import React, {useContext, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from "react-hook-form";
import './wizard.scss';
import Divider from '@mui/material/Divider';
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControlLabel from '@mui/material/FormControlLabel';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import parse from 'html-react-parser';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import "react-datepicker/dist/react-datepicker.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import Datepicker from "./components/datepicker/datepicker"
import DialogModule from "../../modules/dialog/dialog";
import DataTableForm from "../../components/wizard/dataTableForm/dataTableForm";
import { PhoneMask } from '../../components/wizard/masks/phoneMask'
import {createTheme, ThemeProvider} from "@mui/material";
import {StepContext} from "../../contexts/step-context";

export default function Wizard(props){
    const steps = props.steps;
    const initialValues = props.initialValues;
    const propActiveStep = props.activeStep;
    // const [activeStep, setActiveStep] = useState(propActiveStep);
    const {activeStep, setActiveStep} = useContext(StepContext);
    const apiError = useRef(props.apiError);
    const [skipped, setSkipped] = useState(new Set());
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const [confirmTitle, setConfirmTitle] = useState("");
    const [confirmLabel, setConfirmLabel] = useState("");
    const [fileErrors, setFileErrors] = useState([]);
    const {register, formState: { errors, isValid }, handleSubmit, reset, watch, control, trigger, getValues, setValue, setError } = useForm({defaultValues: initialValues});

    let curr_step = steps[activeStep];

    const isStepOptional = (step) => {
        return step.optional !== undefined && step.optional === true;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        if(curr_step.onSubmit  && !confirmOpen){
            handleConfirm()
        }
        else{
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
            submit();
        }
    };

    const handleFileUpload = (event) => {
        setFileErrors([]);
        if(event.target.files[0]?.type === "application/pdf"){
            // console.log(event.target.name)
            // console.log(event.target.files[0])
            let file ={
                name: event.target.name,
                file: event.target.files[0]
            }
            setFiles(prevFiles => [...prevFiles, file])
            setValue(event.target.name, event.target.files[0].name)
        }
        else{
            let fileError = {
                name: event.target.name,
                error: "Please upload a PDF."
            }
            setFileErrors(fileError)
        }

};

    const handleConfirm = () => {
        setConfirmTitle(curr_step.onSubmit.title);
        setConfirmLabel(curr_step.onSubmit.label);
        setConfirmOpen(true);
    }

    // const handleBack = () => {
    //     // console.log(initialValues)
    //     // console.log(getValues())
    //     // console.log(activeStep)
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    //     // props.onBack(activeStep-1)
    //     // console.log("goBack was called.")
    // };

    const handleSkip = () => {
        if (!isStepOptional(curr_step)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
        reset();
    };

    const submit = async () => {
        let step_data = new FormData();
        let values = getValues()
        if(files.length > 0){
            files.forEach(file => step_data.append(file.name, file.file))
        }
        else{
            curr_step.fields.forEach(field => {
                if(field.name in values){
                    // console.log("I am in values.");
                    if(typeof(values[field.name]) === "object"){
                        // console.log("I am a object.");
                        // console.log(values[field.name]);
                        // console.log(JSON.stringify(values[field.name], 0, 2));
                        step_data.append(field.name, JSON.stringify(values[field.name], 0, 2));
                    }
                    else{
                        step_data.append(field.name, values[field.name])
                    }
                }
            })
        }
        step_data.append('step', activeStep+1);
        await props
            .onSubmit(step_data, activeStep)
            .then((res)=>{})
    }

    const dataTableHandleDelete = (name, id) =>{
        let currVal = getValues(name)
        const newArr = currVal.filter((person) => person.id !== id);
        setValue(name, newArr)
    }

    const dataTableHandleChange = (name, data, id="") =>{
        let currVal = getValues(name)
        if(id !== ""){
            let newArr = currVal.map((obj) => {
                if(obj.id === id){
                    return data
                }
                else {
                    return obj
                }
            })
            setValue(name, newArr)

        }
        else{
            let ids = []
            if(currVal !== undefined && currVal.length > 0){
                ids =currVal.map(obj => {
                        return obj.id
                    }
                )
            }
            else{
                ids = [0]
            }
            let maxId = Math.max(...ids)
            data['id'] = maxId+1;
            setValue(name, [...getValues(name), data])
        }
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            trigger(name);
        });

        return () => {
            subscription.unsubscribe();
        }
    }, [watch, activeStep, steps, isValid, trigger, getValues]);

    useEffect(() => {
        if(initialValues){
            reset(initialValues)
        }
    }, [initialValues])

    useEffect(()=>{
        if(propActiveStep){
            // console.log("prop Active Step")
            const values = getValues()
            reset(values)
            // console.log(values)
        }
    }, [propActiveStep])

    const handleChange = (event) => {
        // console.log("I was called for: " + event.target.name)
        setValue(event.target.name, event.target.value);
    };

    const fileInput = React.createRef();

    const theme = createTheme({
        components: {
            MuiInputLabel:{
                styleOverrides:{
                    root:{
                        zIndex: '0'
                    }
                }
            }
        },
    });

     return (
        <div className="wizard">
            <div className="row">
                <div className="col-md-12">
                    <Stepper activeStep={activeStep} alternativeLabel className="mobile_wizard">
                        {steps.map((step, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(step)) {
                                labelProps.optional = (
                                    <Typography variant="caption">Optional</Typography>
                                );
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={step.label} {...stepProps}>
                                    <StepLabel {...labelProps}>{step.label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <Divider/>
                    {/*<StepContent>*/}
                        {
                            activeStep === steps.length ? (
                                <div className="col-md-12 mt-2 pl-5 pr-5">
                                    {/*<Typography sx={{ mt: 2, mb: 1 }}>*/}
                                        <h2>
                                            {props.onCompletionMessage}
                                        </h2>
                                    {/*</Typography>*/}
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        {/*<Button onClick={handleReset}>Reset</Button>*/}
                                    </Box>
                                </div>
                            ) : (
                                <div className="col-md-12 wizard_body">
                                    { apiError.current &&
                                        <div className="alert alert-danger">
                                            <h5><i className="icon fas fa-ban"></i> Alert!</h5>
                                            Oops, Something went wrong and we couldn't save this step. Please try again later.
                                        </div>
                                    }
                                    <div>{parse(curr_step.description ?? "")}</div>
                                    <form onSubmit={handleSubmit(handleNext)} noValidate>
                                        <Grid container>
                                            {
                                                curr_step.fields?.map((field, index) =>{
                                                    const type = field.type;
                                                    const format = field.format;
                                                    const label = field.label;
                                                    const name = field.name;
                                                    const errorText = field.error_text;
                                                    const required = field.required;
                                                    const section = field.section;
                                                    const columns = field.columns;
                                                    const width = field.width;
                                                    const addButton = field.addButton;
                                                    const saveEditButton = field.saveEditButton;
                                                    const textFieldRows = field.rows;
                                                    const skip_field = field.skip_field;
                                                    const sample_url = field.sample_url;
                                                    const sample_message = field.sample_message;
                                                    const file_message = field.file_message;
                                                    let skipField = false;
                                                    if(skip_field){
                                                        if(getValues(name) !== "" && getValues(name)!== skip_field.skip_value){
                                                            skipField = true;
                                                        }
                                                    }
                                                    let size_sm = 6;
                                                    if(section || width){
                                                        if(width){
                                                            size_sm = width;
                                                        }
                                                        else{
                                                            size_sm = 12;
                                                        }
                                                    }
                                                    return(
                                                        <ThemeProvider theme={theme} key={name}>
                                                            <Grid item xs={12} sm={size_sm} sx={{p:'10px'}}>
                                                                {
                                                                    section !== undefined &&
                                                                    <React.Fragment>
                                                                        <Divider textAlign="left" sx={{fontWeight:"bold"}}>{section}</Divider>
                                                                    </React.Fragment>

                                                                }
                                                                {
                                                                    type === "checkbox" &&
                                                                    <React.Fragment>
                                                                        <FormControlLabel
                                                                            key={name}
                                                                            control={
                                                                                <Controller
                                                                                    control={control}
                                                                                    name={name}
                                                                                    defaultValue={false}
                                                                                    rules={{required: required}}
                                                                                    render={({
                                                                                                 field: {onChange, value, ref, onBlur},
                                                                                                 fieldState: { invalid, isTouched, isDirty, error },
                                                                                             }) =>
                                                                                        <Checkbox
                                                                                            onBlur={onBlur}
                                                                                            onChange={onChange}
                                                                                            checked={value}
                                                                                            key={name}
                                                                                            required={required}
                                                                                            {...field}
                                                                                        />
                                                                                    }
                                                                                />
                                                                            }
                                                                            label={parse(label)}/>

                                                                        <FormHelperText sx={{fontSize: 14, fontWeight: "bold"}} className="text-danger">
                                                                            {errors[name]?.type === 'required' && errorText}
                                                                            {errors[name] && errors[name].message}
                                                                        </FormHelperText>
                                                                    </React.Fragment>
                                                                }
                                                                {
                                                                    ((type === "text" && format === undefined) || (type === "number" && format === undefined)) &&
                                                                    <Controller
                                                                        control={control}
                                                                        name={name}
                                                                        defaultValue={type === "number" ? "0" : ""}
                                                                        rules={{required: required}}
                                                                        render={(
                                                                            {
                                                                                field: {name, value, onBlur},
                                                                            }) =>
                                                                            <>
                                                                                <TextField
                                                                                    className="w-100"
                                                                                    name={name}
                                                                                    value={value}
                                                                                    onBlur={onBlur}
                                                                                    InputProps={{ inputProps: { min: 1 } }}
                                                                                    type={type}
                                                                                    onChange={event=>{
                                                                                        handleChange(event)
                                                                                    }}
                                                                                    multiline={textFieldRows > 1}
                                                                                    rows={textFieldRows ?? 1}
                                                                                    label={label}
                                                                                    required={required}
                                                                                />
                                                                                <FormHelperText sx={{fontSize: 14, fontWeight: "bold"}} className="text-danger">
                                                                                    {errors[name]?.type === 'required' && errorText}
                                                                                    {errors[name] && errors[name].message}
                                                                                </FormHelperText>
                                                                            </>
                                                                        }
                                                                    />
                                                                }
                                                                {
                                                                    (type === "text" && format === "tel" ) &&(
                                                                        <FormControl fullWidth>
                                                                            <InputLabel required={required} htmlFor={name}>{label}</InputLabel>
                                                                            <OutlinedInput
                                                                                id={name}
                                                                                {...field}
                                                                                { ...register(name, {required: required, pattern: {value: /\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, message: errorText}}) }
                                                                                value={getValues(name)}
                                                                                onChange={event=>{
                                                                                    handleChange(event)
                                                                                }}
                                                                                // inputComponent={PhoneMask}
                                                                                label={label}
                                                                                inputProps={{ minLength: 10, maxLength: 15 }}
                                                                            />
                                                                            <FormHelperText sx={{fontSize: 14, fontWeight: "bold"}} className="text-danger">
                                                                                {errors[name] ? errorText : ""}
                                                                            </FormHelperText>
                                                                        </FormControl>

                                                                    )
                                                                }
                                                                {
                                                                    type === "date" &&
                                                                    <Controller
                                                                        control={control}
                                                                        name={name}
                                                                        defaultValue={getValues(name)}
                                                                        rules={{required: required}}
                                                                        helperText={errors[name]?.type === 'required' ? errorText : ""}
                                                                        error={errors[name]?.type === 'required'}
                                                                        render={(
                                                                            {
                                                                                field: {onChange, value},
                                                                            }) =>
                                                                            <Datepicker
                                                                                onChange={onChange}
                                                                                label={label}
                                                                                value={value}
                                                                                required={required}
                                                                            />
                                                                        }
                                                                    />
                                                                }
                                                                {
                                                                    type === "select" &&
                                                                    <Controller
                                                                        control={control}
                                                                        name={name}
                                                                        defaultValue={""}
                                                                        rules={{required: required}}
                                                                        helperText={errors[name]?.type === 'required' ? errorText : ""}
                                                                        errorText={errorText}
                                                                        render={(
                                                                            {
                                                                                field: {onChange, value, ref, onBlur},
                                                                                fieldState: { error }
                                                                            }) =>
                                                                            <FormControl fullWidth>
                                                                                <InputLabel required={required}>{label}</InputLabel>
                                                                                <Select defaultValue={""} value={value} onChange={onChange} onBlur={onBlur} ref={ref}>
                                                                                    <MenuItem value="">Select One</MenuItem>
                                                                                    {Object.entries(field.options).map(([abrv, state]) => {
                                                                                        return(
                                                                                            <MenuItem key={abrv} value={abrv}>{state}</MenuItem>
                                                                                        )
                                                                                    })}
                                                                                </Select>
                                                                                <FormHelperText className="text-danger">{error?.type === 'required' ? errorText : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        }
                                                                    />

                                                                }
                                                                {
                                                                    type === "file" &&
                                                                    <FormControl fullWidth>
                                                                        {
                                                                            file_message &&
                                                                            <div className="callout callout-info">

                                                                                <h5>{label}</h5>
                                                                                {parse(file_message)}
                                                                            </div>
                                                                        }
                                                                        <label htmlFor={name}>
                                                                            {
                                                                                getValues(name) ?
                                                                                    <div>
                                                                                        {label} - <span className={""}> ({getValues(name)}) <span className={"text-blue font-weight-normal"}>Click here to replace.</span></span>
                                                                                    </div>

                                                                                    :
                                                                                // (!file_message ?
                                                                                    <div>
                                                                                        {label} - <span className={"font-weight-normal"}><a className={"d-inline text-blue"} href={sample_url} target={"_blank"} rel="noreferrer">{sample_message}</a></span>
                                                                                    </div>
                                                                                // : '')
                                                                            }
                                                                        </label>
                                                                        <input
                                                                            id={name}
                                                                            {...register(name, {required: required})}
                                                                            onChange={handleFileUpload}
                                                                            ref={fileInput}
                                                                            accept="application/pdf"
                                                                            type="file"
                                                                            className={getValues(name) ? "d-none" : ""}
                                                                        />
                                                                        <h6 className="text-danger">{fileErrors.name === name && fileErrors.error}</h6>
                                                                    </FormControl>
                                                                }
                                                                {
                                                                    type === "dataTable" &&
                                                                    <div>
                                                                        <DataTableForm
                                                                            name={name}
                                                                            label={label}
                                                                            dataTableValues={getValues(name)}
                                                                            field={field}
                                                                            columns={columns}
                                                                            addButton={addButton}
                                                                            saveEditButton={saveEditButton}
                                                                            onChange={dataTableHandleChange}
                                                                            onDelete={dataTableHandleDelete}
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    skipField &&
                                                                    <div>
                                                                        <Controller
                                                                            control={control}
                                                                            name={skip_field.name}
                                                                            defaultValue={type === "number" ? "0" : ""}
                                                                            rules={{required: required}}
                                                                            render={(
                                                                                {
                                                                                    field: {name, value, onBlur},
                                                                                }) =>
                                                                                <>
                                                                                    <TextField
                                                                                        className="w-100"
                                                                                        name={name}
                                                                                        value={value}
                                                                                        onBlur={onBlur}
                                                                                        InputProps={{ inputProps: { min: 1 } }}
                                                                                        type={skip_field.type}
                                                                                        onChange={event=>{
                                                                                            handleChange(event)
                                                                                        }}
                                                                                        multiline={skip_field.textFieldRows > 1}
                                                                                        rows={skip_field.textFieldRows ?? 1}
                                                                                        label={skip_field.label}
                                                                                        required={skip_field.required}
                                                                                    />
                                                                                    <FormHelperText sx={{fontSize: 14, fontWeight: "bold"}} className="text-danger">
                                                                                        {errors[name]?.type === 'required' && skip_field.errorText}
                                                                                        {errors[name] && errors[name].message}
                                                                                    </FormHelperText>
                                                                                </>
                                                                            }
                                                                        />
                                                                    </div>
                                                                }
                                                            </Grid>
                                                        </ThemeProvider>
                                                    )
                                                })}
                                        </Grid>
                                        <Divider textAlign="left" sx={{fontWeight:"bold"}}/>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

                                            {/*<Button*/}
                                            {/*    color="inherit"*/}
                                            {/*    disabled={activeStep === 0}*/}
                                            {/*    onClick={handleBack}*/}
                                            {/*    sx={{ mr: 1 }}*/}
                                            {/*    className="back"*/}
                                            {/*>*/}
                                            {/*    Back*/}
                                            {/*</Button>*/}
                                            <Box sx={{ flex: '1 1 auto' }} />
                                            {isStepOptional(curr_step) && (
                                                <Button type="button" onClick={handleSkip}>
                                                    Skip
                                                </Button>
                                            )}

                                            <Button type="submit" disabled={!isValid} className="btn btn-success">
                                                {activeStep === steps.length - 1 ? 'Submit' : 'Save & Next'}
                                            </Button>
                                        </Box>
                                    </form>
                                </div>
                            )
                        }
                    {/*</StepContent>*/}
                </div>
            </div>
            <DialogModule
                open={confirmOpen}
                title={confirmTitle}
                children={confirmLabel}
                setOpen={setConfirmOpen}
                onConfirm={handleNext}
                onDecline={()=> console.log("declined")}
            />
            {/*<pre>{JSON.stringify(getValues(), 0, 2)}</pre>*/}
        </div>
     )
}

Wizard.propTypes = {
    steps: PropTypes.array.isRequired,
    onSubmit: PropTypes.func,
    onBack: PropTypes.func.isRequired,
    onCompletionMessage: PropTypes.string.isRequired
}