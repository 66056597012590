export function handleResponse(response) {
    if (response.results) {
        return response.results;
    }

    if (response.data) {
        return response.data;
    }

    if(response.response){
        return response.response;
    }
    return response;
}

export function handleError(error) {
    if (error.data) {
        return error.data;
    }
    if(error.response){
        return error.response;
    }
    return error;
}