import useToken from "./useToken";
import jwtDecode from "jwt-decode";
import {useEffect, useState} from "react";

const useAuth = () => {
    const [token] = useToken();
    const [isAuth, setIsAuthInternal] = useState(() => {
        if (token !== null){
            let decodedToken = jwtDecode(token);
            let currentDate = new Date();
            return decodedToken.exp * 1000 >= currentDate.getTime();
        }
    });

    // console.log("I am in useAuth.")
    // console.log(token)

    return isAuth

}

export default useAuth;