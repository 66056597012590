import {useState} from 'react';
import jwtDecode from "jwt-decode";

const useToken = () => {
    const [token, setTokenInternal] = useState(() => {
        if (localStorage["token"]) {
            // let decodedToken = jwtDecode(localStorage["token"]);
            return localStorage.getItem('token');
        }
        else{
            return null;
        }
    });

    const setToken = (newToken) =>{
        if(newToken !== undefined){
            localStorage.setItem('token', newToken);
            setTokenInternal(newToken);
        }
    }
    return [token, setToken];
}

export default useToken;