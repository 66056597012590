export const capitalizeFirst = str => {
    if(str !== undefined) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    return ''
};

export function formatLocalDate(value) {
    if(value !== undefined) {
        return (new Date(value)).toLocaleDateString();
    }

    return '';
}
