import ApiCore from "../utilities/core";

const url = 'auth/logout';

// plural and single may be used for message logic if needed in the ApiCore class.

const LogoutApi = new ApiCore({
    post: true,
    url: url,
    requireToken: true
});

export default LogoutApi;
