import React, {useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import useUser from "../../../services/auth/useUser";
import usePermissions from "../../../services/auth/usePermissions";
import {apiEmpower} from "../../../services/api/empower";
import {apiSfa} from "../../../services/api/sfa";

function Sidebar() {
    const user = useUser();
    const [userCan, userIs] = usePermissions();
    const [enableEmpowerApplication, setEnableEmpowerApplication] = useState(false);
    const [enableSfaApplication, setEnableSfaApplication] = useState(false);
    let name = user.name;
    let parts = name.split(' ');
    let initials = '';
    for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== '') {
            initials += parts[i][0];
        }
    }

    const getSettings = async () => {
        apiEmpower.getSettings().then(res => {
            if(res.enable_empower_application){
                setEnableEmpowerApplication(res.enable_empower_application);
                window.localStorage.setItem('empower_application_sidebar', res.enable_empower_application);
            }
        });
        apiSfa.getSettings().then(res => {
            if(res.enable_sfa_application){
                setEnableSfaApplication(res.enable_sfa_application);
                window.localStorage.setItem('sfa_application_sidebar', res.enable_sfa_application);
            }
        });
    }

    useEffect(() => {
        const empowerSidebar = window.localStorage.getItem('empower_application_sidebar');
        const sfaSidebar = window.localStorage.getItem('sfa_application_sidebar');
        if(empowerSidebar !== 'undefined'){
            setEnableEmpowerApplication(JSON.parse(empowerSidebar))
        }

        if (sfaSidebar !== 'undefined') {
            setEnableSfaApplication(JSON.parse(sfaSidebar));
        }
        getSettings()
    }, [])

    return (
        <aside className="main-sidebar sidebar-light-dark elevation-1">
            {/* Brand Logo */}
            <Link to={"/"} className="brand-link">
                <img src="/images/ICNA_Logo.png" alt="ICNA Relief Logo" className="brand-image" />
                {/*<img src="../../dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />*/}
                <span className="brand-text font-weight-bold">ICNA Relief</span>
            </Link>
            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar user (optional) */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        {/*<img src="dist/img/boxed-bg.png" className="" alt="User Image" />*/}
                        <div className="profile_label">{initials}</div>
                    </div>
                    <div className="info">
                        <div className="d-block" id="name">{name}</div>
                    </div>
                </div>
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column nav-collapse-hide-child" data-widget="treeview" role="menu" data-accordion="false">
                        {
                            userIs('admin') &&
                            <li className="nav-item">
                                <NavLink  className="nav-link" to="/dashboard">
                                    <i className="nav-icon fas fa-tachometer-alt" />
                                    <p>Dashboard</p>
                                </NavLink>
                            </li>
                        }
                        <li className="nav-item">
                            <NavLink  className="nav-link" to="/applications" id="applications" >
                                <i className="nav-icon fas fa-folder text-success" />
                                <p>Applications</p>
                            </NavLink>
                        </li>
                        {
                            // role === "admin" &&
                            // permissions.findIndex(e => e.slug === 'apply.sfa') > -1 &&
                            (userCan('apply.sfa') && enableSfaApplication) &&
                            <li className="nav-item">
                                <NavLink  className="nav-link" to="/sfa">
                                    <i className="nav-icon fas fa-file-alt text-primary" />
                                    <p>SFA Application</p>
                                </NavLink>
                            </li>
                        }
                        {
                            // role === "admin" &&
                            (userCan('apply.empower') && enableEmpowerApplication) &&
                            <li className="nav-item">
                                <NavLink  className="nav-link" to="/empower">
                                    <i className="nav-icon fas fa-file-alt text-warning" />
                                    <p>EMPOWER Application</p>
                                </NavLink>
                            </li>
                        }
                        {
                            userCan('view.users') &&
                            <li className="nav-item">
                                <NavLink  className="nav-link" to="/users">
                                    <i className="nav-icon fas fa-users" />
                                    <p>Users</p>
                                </NavLink>
                            </li>
                        }
                        {
                            userCan('view.reports') &&
                            <li className="nav-item">
                                <NavLink  className="nav-link" to="/reports">
                                    <i className="nav-icon fas fa-download text-info" />
                                    <p>Reports</p>
                                </NavLink>
                            </li>
                        }
                        {
                            userCan('view.settings') &&
                            <li className="nav-item">
                                <NavLink  className="nav-link" to="/settings">
                                    <i className="nav-icon fas fa-gear text-gray" />
                                    <p>Settings</p>
                                </NavLink>
                            </li>
                        }
                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
        </aside>
    )
}

export default Sidebar;