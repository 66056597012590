import ApiCore from "../utilities/core";
import {apiProvider} from "../utilities/provider";

const url = 'sfa';
const plural = 'applications';
const single = 'application';

// plural and single may be used for message logic if needed in the ApiCore class.

export const apiSfa = new ApiCore({
    getAll: true,
    getSingle: true,
    post: true,
    put: true,
    patch: true,
    delete: false,
    url: url,
    plural: plural,
    single: single,
    requireToken: true
});

apiSfa.massUpdate = () => {
    // Add custom api call logic here
}

apiSfa.getSettings = () => {
    return apiProvider.getAll(url+'/settings');
}