import React from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@material-ui/core/IconButton";

function DialogModule(props) {
    const { title, children, open, setOpen, onConfirm, onDecline, yesButton = "Yes", noButton = "No", size = "sm", fullWidth = false, hideButtons= false } = props;
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
            maxWidth={size}
            fullWidth={fullWidth}
        >
            <DialogTitle id="confirm-dialog">
                {title}
                <IconButton className="float-right" onClick={() => {setOpen(false)}} style={{cursor:'pointer', float:'right', marginTop: '-8px', width: '20px'}}>
                    <CloseIcon  />
                </IconButton>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions hidden={hideButtons}>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(false)
                        onDecline()
                    }}
                    color="secondary"
                >
                    {noButton}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                    // color="default"
                >
                    {yesButton}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogModule;