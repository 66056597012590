import React, {useEffect, useRef, useState, useMemo, useCallback} from 'react';
import Main from "../../modules/main/main";
import usePermissions from "../../services/auth/usePermissions";
import {useNavigate} from "react-router-dom";
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {apiUsers} from "../../services/api/users"; // Optional theme CSS
import {capitalizeFirst} from "../../services/common";
import DialogModule from "../../modules/dialog/dialog";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useForm} from "react-hook-form";
function Users(props) {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [userCan] = usePermissions();
    const breadcrumbs = [
        {"link": "/", "name": "Users", "active": true}
    ];

    const gridRef = useRef(); // Optional - for accessing Grid's API
    const [rowData, setRowData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [allRoles, setAllRoles] = useState([]);
    const [error, setError] = useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [columnDefs] = useState([
        {
            field: 'name'
        },
        {
            field: 'email'
        },
        {
            headerName: 'Type (Role)',
            valueGetter: params => capitalizeFirst(params.data.roles[0].name),
        },
        {
            headerName: 'Action',
            cellRenderer: params => {
                if(userCan('edit.users')) {
                    return (
                        <span>
                        <button className={'btn btn-primary mb-2'} onClick={() => navigate('/users/'+params.data.id)}>Edit</button>
                    </span>
                    );
                }

            },
            editable: false,
            filter: false,
            minWidth: 100,
            maxWidth: 100,
        }
    ]);

    const defaultColDef = useMemo( ()=> ({
        sortable: true,
        resizable: true,
        filter: true,
        floatingFilter: true,
        suppressMenu: true
        // filter: 'agTextColumnFilter',
    }));

    let fetchUsers = React.useCallback(async ()=>{
        apiUsers.getAll().then(res=>{
            setRowData(res.users);
            setAllRoles(res.roles);
            setLoading(false)
        })
    })

    const gridOptions = {
        // PROPERTIES
        // Objects like myRowData and myColDefs would be created in your application
        rowData: rowData,
        columnDefs: columnDefs,
        pagination: true,
        rowSelection: 'single',

        // EVENTS
        // Add event handlers
        // onRowClicked: event => console.log('A row was clicked'),
        onColumnResized: event => event.api.sizeColumnsToFit(),
        onGridReady: event => {
            event.api.sizeColumnsToFit();
            event.columnApi.resetColumnState();
        },

        // CALLBACKS
        getRowHeight: (params) => 25
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Please enter a name.'),
        email: Yup.string().required('Please enter a email.'),
        password: Yup.string().required('Please Enter a password'),
        passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Password must match.'),
        role: Yup.string().required('Please select a role'),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    };
    const {
        register,
        handleSubmit,
        reset,
        formState
    } = useForm(formOptions);
    const { errors, isValid } = formState;

    function onSave(data) {
        setError('');
        apiUsers.post(data).then((res) => {
          if(res.status){
              setError(res.data);
          }
          else {
              handleClose();
              fetchUsers();
              reset();
              navigate(`/users/${res.user.id}`);
          }
        });
        return false;
    }

    useEffect(() => {
        if(!userCan('view.users')){
            navigate('/');
        }
        fetchUsers()
    }, [])

    return (
        <Main title="Users" breadcrumbs={breadcrumbs} loading={loading}>
            {userCan('create.users') &&
                <div className="text-right">
                    <button className={'btn btn-primary mb-2'} onClick={handleOpen}>Add User</button>
                </div>
            }
            <div className="ag-theme-alpine" style={{ height: 800 }}>
                <AgGridReact
                    style={{ width: '100%', height: '100%;' }}
                    ref={gridRef} // Ref for accessing Grid's API

                    rowData={rowData} // Row Data for Rows

                    // columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    gridOptions={gridOptions}
                    getRowHeight={1000}
                    // animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    // rowSelection='multiple' // Options - allows click selection of rows

                    // onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                ></AgGridReact>
            </div>

            <DialogModule
                open={open}
                onClose={handleClose}
                setOpen={setOpen}
                title="Add User"
                size="md"
                fullWidth={true}
                hideButtons={true}
            >
                <form className="form-horizontal" onSubmit={handleSubmit(onSave)}>
                    {error && <div className="alert alert-danger">
                        <h5><i className="icon fas fa-ban"></i> Error!</h5>
                        {error}
                    </div>}
                    <div className="form-group row">
                        <label htmlFor="inputName" className="col-sm-2 col-form-label">Name</label>
                        <div className="col-sm-10">
                            <input
                                type="text"
                                name="name"
                                {...register('name')}
                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                placeholder="Name"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputEmail" className="col-sm-2 col-form-label">Email</label>
                        <div className="col-sm-10">
                            <input
                                type="email"
                                name="email"
                                {...register('email')}
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                placeholder="Email"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputExperience" className="col-sm-2 col-form-label">Role</label>
                        <div className="col-sm-10">
                            {/*<textarea className="form-control" id="inputExperience" placeholder="Role"></textarea>*/}
                            <select
                                name="role"
                                className={`form-control ${errors.role ? 'is-invalid' : ''}`}
                                {...register('role')}
                            >
                                {
                                    allRoles.map((role) => (
                                        <option key={role['id']} value={role['id']}>{role['name']}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputSkills" className="col-sm-2 col-form-label">Password</label>
                        <div className="col-sm-10">
                            <input
                                type="password"
                                name="password"
                                {...register('password')}
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                placeholder="Password"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputSkills" className="col-sm-2 col-form-label">Confirm Password</label>
                        <div className="col-sm-10">
                            <input
                                type="password"
                                name="passwordConfirm"
                                {...register('passwordConfirm')}
                                className={`form-control ${errors.passwordConfirm ? 'is-invalid' : ''}`}
                                placeholder="Confirm Password"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="offset-sm-2 col-sm-10">
                            <button type="submit" className="btn btn-primary" disabled={!isValid}>Add</button>
                        </div>
                    </div>
                </form>
            </DialogModule>
        </Main>
    );
}

export default Users;