import React, {useEffect, useRef, useState} from 'react';
import Main from "../../modules/main/main";
import {useNavigate, useParams} from "react-router-dom";
import {apiEmpower} from "../../services/api/empower";
import {apiEmpowerReview} from "../../services/api/empower-review";
import YouTube from 'react-youtube';
import usePermissions from "../../services/auth/usePermissions";
import PdfViewer from "../../modules/PdfViewer/PdfViewer";
import {useForm} from "react-hook-form";
import Button from "@mui/material/Button";
import DialogModule from "../../modules/dialog/dialog";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

function EmpowerReview(props) {
    const isInitialMount = useRef(true);
    const navigate = useNavigate();
    const {id} = useParams();
    const [createMode, setCreateMode] = useState(true);
    const [userCan] = usePermissions();
    const [loading, setLoading] = useState(true);
    const [formValues, setFormValues] = useState({});
    const [cannedMessages, setCannedMessages] = useState({});
    const [videoId, setVideoId] = useState("");
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [applicationStatus, setApplicationStatus] = useState("");
    const validationSchema = Yup.object().shape({
        executive_summary_score: Yup.number()
            .required("Please enter a score.")
            .typeError("Please enter a score.")
            .min(0, "Number must be greater than or equal to 0")
            .max(5, "Number must be less than or equal to 5"),
        problem_summary_score: Yup.number()
            .required('Please enter a score.')
            .typeError("Please enter a score.")
            .min(0, "Number must be greater than or equal to 0")
            .max(5, "Number must be less than or equal to 5"),
        current_objective_score: Yup.number()
            .required('Please enter a score.')
            .typeError("Please enter a score.")
            .min(0, "Number must be greater than or equal to 0")
            .max(5, "Number must be less than or equal to 5"),
        project_timeline_score: Yup.number()
            .required('Please enter a score.')
            .typeError("Please enter a score.")
            .min(0, "Number must be greater than or equal to 0")
            .max(5, "Number must be less than or equal to 5"),
        detailed_budget_score: Yup.number()
            .required('Please enter a score.')
            .typeError("Please enter a score.")
            .min(0, "Number must be greater than or equal to 0")
            .max(5, "Number must be less than or equal to 5"),
        youtube_video_score: Yup.number()
            .required('Please enter a score.')
            .typeError("Please enter a score.")
            .min(0, "Number must be greater than or equal to 0")
            .max(10, "Number must be less than or equal to 10"),
        resume_score: Yup.number()
            .required('Please enter a score.')
            .typeError("Please enter a score.")
            .min(0, "Number must be greater than or equal to 0")
            .max(5, "Number must be less than or equal to 5"),
        lor_score: Yup.number()
            .required('Please enter a score.')
            .typeError("Please enter a score.")
            .min(0, "Number must be greater than or equal to 0")
            .max(10, "Number must be less than or equal to 10"),
        amount_approved: Yup.number()
            .required('Please enter the amount approved.')
            .typeError("Please enter the amount approved."),
        reviewer_notes: Yup.string()
            .required("Please enter a note")
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
        defaultValues: {
            empower_id: Number(id),
            executive_summary_score: 0,
            problem_summary_score: 0,
            current_objective_score: 0,
            project_timeline_score: 0,
            detailed_budget_score: 0,
            youtube_video_score: 0,
            resume_score: 0,
            lor_score: 0,
            amount_approved: 0
        }
    };
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors, isValid},
        watch,
        getValues,
        reset
    } = useForm(formOptions);
    const [applicationScore, setApplicationScore] = useState(0);

    let breadcrumbs = [
        {"name": "Dashboard", "active": false},
        {"name": "EMPOWER Application", "active": false},
        {"name": id, "active": true}
    ];

    useEffect(() => {
        const subscription = watch((values, name) => {
            let total =
                Number(getValues("executive_summary_score")) +
                Number(getValues("problem_summary_score")) +
                Number(getValues("current_objective_score")) +
                Number(getValues("project_timeline_score")) +
                Number(getValues("detailed_budget_score")) +
                Number(getValues("youtube_video_score")) +
                Number(getValues("resume_score")) +
                Number(getValues("lor_score"));
            setApplicationScore(prevState => total)
        });
        return () => subscription.unsubscribe();
    }, [watch, getValues]);


    const fetchData = async (id) => {
        await apiEmpower.getSingle(id).then(res => {
            if (res.status === 404) {
                navigate('/')
            }
            setFormValues(res)
            setLoading(false)
        })

        await apiEmpowerReview.getSingle(id).then(res => {
            if (res.reviewer_notes) {
                // console.log(res)
                setCreateMode(false)
                res.empower_id = Number(id);
                reset(res)

            } else {

            }
            setCannedMessages(res.messages)
        })
    }

    const onSubmit = async (data = getValues()) => {
        data['status'] = applicationStatus
        if (createMode) {
            await apiEmpowerReview.post(data).then(res => {
                navigate('/applications')
            })
        } else {
            data['_method'] = "PUT";
            await apiEmpowerReview.patch(data.id, data).then(res => {
                // console.log(res)
                navigate('/applications')
            })
        }
    }

    useEffect(() => {
        getYoutubeVideoId()
    }, [formValues])

    function setMessage(type, confirmed = false) {
        if (type === "approved") {
            setValue('message_to_applicant', cannedMessages['approved'])
        } else if (type === "need_more_info") {
            setValue('message_to_applicant', cannedMessages['need_more_info'])
        } else if (type === "reject") {
            setValue('message_to_applicant', cannedMessages['rejected'])
        }
    }

    const getYoutubeVideoId = () => {
        if (formValues['video_clip']) {
            let p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|shorts\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
            let matches = formValues['video_clip'].match(p);
            setVideoId(matches[1]);
        }
    }

    useEffect(() => {
        if (userCan('review.empower')) {
            if (isInitialMount.current) {
                isInitialMount.current = false;
                fetchData(id);
            }
        } else {
            navigate('/')
        }
    }, [])

    const opts = {
        width: '100%',
    };


    return (
        <Main title="EMPOWER Application" breadcrumbs={breadcrumbs} loading={loading}>
            <div className="col-md-12">
                {/*Contact Info*/}
                <div className="card card-success">
                    <div className="card-header" data-card-widget="collapse">
                        <h3 className="card-title">Contact Info</h3>
                        <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse"><i
                                className="fas fa-minus"></i>
                            </button>
                        </div>

                    </div>

                    <div className="card-body" style={{display: 'block'}}>
                        <table className="table table-striped">
                            <tbody>
                            <tr className="row">
                                <th className="col-md-4">Teacher's First name</th>
                                <td className="col-md-8">{formValues['teacherFirstName']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Teacher's Last name</th>
                                <td className="col-md-8">{formValues['teacherLastName']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Teacher's Address</th>
                                <td className="col-md-8">
                                    {formValues['address']}
                                    <br/>
                                    {formValues['address2']}
                                    <br/>
                                    {formValues['city']}, {formValues['state']} {formValues['zipCode']}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Teacher's Phone number</th>
                                <td className="col-md-8">{formValues['phone']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Teacher's Email Address</th>
                                <td className="col-md-8">{formValues['email']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Teacher's Grade level that they teach</th>
                                <td className="col-md-8">{formValues['gradeYouTeach']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Teacher's Subject that they teach</th>
                                <td className="col-md-8">{formValues['subject_you_teach']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Number of students in the classroom</th>
                                <td className="col-md-8">{formValues['number_of_students']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Do you have confirmation from your school that you will be teaching during the upcoming school year (e.g., signed contract, letter of intent, or verbal confirmation)?</th>
                                <td className="col-md-8">{formValues['teaching_confirmation']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Are you the person responsible for the execution of the
                                    grant?
                                </th>
                                <td className="col-md-8">{formValues['primary_responsible']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Have you previously applied for EMPOWER Grant?</th>
                                <td className="col-md-8">{formValues['previously_applied']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Have you previously been awarded the EMPOWER Grant?</th>
                                <td className="col-md-8">{formValues['previously_awarded']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">If yes, which year?</th>
                                <td className="col-md-8">{formValues['previously_awarded_year']}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                {/*School Info*/}
                <div className="card card-success">
                    <div className="card-header" data-card-widget="collapse">
                        <h3 className="card-title">School Info</h3>
                        <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse"><i
                                className="fas fa-minus"></i>
                            </button>
                        </div>

                    </div>

                    <div className="card-body" style={{display: 'block'}}>
                        <table className="table table-striped">
                            <tbody>
                            <tr className="row">
                                <th className="col-md-4">School's Name</th>
                                <td className="col-md-8">{formValues['schoolName']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">School District Name</th>
                                <td className="col-md-8">{formValues['schoolDistrict']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">School's Principal</th>
                                <td className="col-md-8">{formValues['schoolPrincipal']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">School's Website</th>
                                <td className="col-md-8">{formValues['schoolWebsite']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4"></th>
                                <td className="col-md-8">
                                    {formValues['schoolAddress']}
                                    <br/>
                                    {formValues['schoolAddress2']}
                                    <br/>
                                    {formValues['schoolCity']}, {formValues['schoolState']} {formValues['schoolZipCode']}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">School's Phone number</th>
                                <td className="col-md-8">{formValues['schoolPhone']}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                {/*Grant Request Summary*/}
                <div className="card card-success">
                    <div className="card-header" data-card-widget="collapse">
                        <h3 className="card-title">Grant Request Summary</h3>
                        <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse"><i
                                className="fas fa-minus"></i>
                            </button>
                        </div>

                    </div>

                    <div className="card-body" style={{display: 'block'}}>
                        <table className="table table-striped">
                            <tbody>
                            <tr className="row">
                                <th className="col-md-4">Age</th>
                                <td className="col-md-8">{formValues['age']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Ethnicity</th>
                                <td className="col-md-8">{formValues['ethnicity']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Gender</th>
                                <td className="col-md-8">{formValues['gender']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Executive Summary</th>
                                <td className="col-md-6">{formValues['executive_summary']}</td>
                                <td className="col-md-2">
                                    <div className="input-group mb-3">
                                        <input type="number" {...register("executive_summary_score")}
                                               className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">/5</span>
                                        </div>
                                    </div>
                                    {errors.executive_summary_score &&
                                        <p className={"text-danger"}>{errors.executive_summary_score.message}</p>}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Describe the problem or community need that the educator
                                    addresses
                                </th>
                                <td className="col-md-6">{formValues['describe_the_problem']}</td>
                                <td className="col-md-2">
                                    <div className="input-group mb-3">
                                        <input type="number" {...register("problem_summary_score")}
                                               className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">/5</span>
                                        </div>
                                    </div>
                                    {errors.problem_summary_score &&
                                        <p className={"text-danger"}>{errors.problem_summary_score.message}</p>}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Current classroom objectives</th>
                                <td className="col-md-6">{formValues['current_classroom_objectives']}</td>
                                <td className="col-md-2">
                                    <div className="input-group mb-3">
                                        <input type="number" {...register("current_objective_score")}
                                               className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">/5</span>
                                        </div>
                                    </div>
                                    {errors.current_objective_score &&
                                        <p className={"text-danger"}>{errors.current_objective_score.message}</p>}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Project timeline</th>
                                <td className="col-md-6">{formValues['project_timeline']}</td>
                                <td className="col-md-2">
                                    <div className="input-group mb-3">
                                        <input type="number" {...register("project_timeline_score")}
                                               className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">/5</span>
                                        </div>
                                    </div>
                                    {errors.project_timeline_score &&
                                        <p className={"text-danger"}>{errors.project_timeline_score.message}</p>}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Requested amount</th>
                                <td className="col-md-8">{formValues['requested_amount']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Detailed project budget</th>
                                <td className="col-md-6">{formValues['detailed_project_budget']}</td>
                                <td className="col-md-2">
                                    <div className="input-group mb-3">
                                        <input type="number" {...register("detailed_budget_score")}
                                               className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">/5</span>
                                        </div>
                                    </div>
                                    {errors.detailed_budget_score &&
                                        <p className={"text-danger"}>{errors.detailed_budget_score.message}</p>}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                {/*Youtube Video*/}
                <div className="card card-success">
                    <div className="card-header" data-card-widget="collapse">
                        <h3 className="card-title">Youtube Video</h3>
                        <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse"><i
                                className="fas fa-minus"></i>
                            </button>
                        </div>

                    </div>

                    <div className="card-body" style={{display: 'block'}}>
                        <table className="table table-striped">
                            <tbody>
                            <tr className="row">
                                <th className="col-md-4">Youtube Video</th>
                                <td className="col-md-6">
                                    {
                                        videoId !== "" &&
                                        <YouTube
                                            videoId={videoId}
                                            opts={opts}
                                        />

                                    }
                                    {
                                        videoId === "" &&
                                        "Participant didn't submit a video."
                                    }
                                </td>
                                <td className="col-md-2">
                                    <div className="input-group mb-3">
                                        <input type="number" {...register("youtube_video_score")}
                                               className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">/10</span>
                                        </div>
                                    </div>
                                    {errors.youtube_video_score &&
                                        <p className={"text-danger"}>{errors.youtube_video_score.message}</p>}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                {/*File Uploads*/}
                <div className="card card-success">
                    <div className="card-header" data-card-widget="collapse">
                        <h3 className="card-title">File Uploads</h3>
                        <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse"><i
                                className="fas fa-minus"></i>
                            </button>
                        </div>
                    </div>

                    <div className="card-body" style={{display: 'block'}}>
                        <table className="table table-striped">
                            <tbody>
                            <tr className="row">
                                <th className="col-md-4">Resume</th>
                                <td className="col-md-6">
                                    {formValues['resume_location'] !== "" && <PdfViewer
                                        pdf={process.env.REACT_APP_AZURE_STORAGE_URL + formValues['resume_location']}
                                        name={"resume"}
                                        title={"Resume"}
                                    />}
                                </td>
                                <td className="col-md-2">
                                    <div className="input-group mb-3">
                                        <input type="number" {...register("resume_score")} className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">/5</span>
                                        </div>
                                    </div>
                                    {errors.resume_score &&
                                        <p className={"text-danger"}>{errors.resume_score.message}</p>}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Letter of Recommendation from the School Principal</th>
                                <td className="col-md-6">
                                    {formValues['letterOfRecommendationFromYourSchoolPrincipal_location'] !== "" &&
                                        <PdfViewer
                                            pdf={process.env.REACT_APP_AZURE_STORAGE_URL + formValues["letterOfRecommendationFromYourSchoolPrincipal_location"]}
                                            name={'letterOfRecommendationFromYourSchoolPrincipal'}
                                            title={"Letter of Recommendation from the School Principal"}
                                        />}
                                </td>
                                <td className="col-md-2">
                                    <div className="input-group mb-3">
                                        <input type="number" {...register("lor_score")} className="form-control"/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">/10</span>
                                        </div>
                                    </div>
                                    {errors.lor_score && <p className={"text-danger"}>{errors.lor_score.message}</p>}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Social Security Card</th>
                                <td className="col-md-8">
                                    {formValues['socialSecurityCard_location'] !== "" && <PdfViewer
                                        pdf={process.env.REACT_APP_AZURE_STORAGE_URL + formValues["socialSecurityCard_location"]}
                                        name={"socialSecurityCard"}
                                        title={"Social Security Card"}
                                    />}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Personal W9</th>
                                <td className="col-md-8">
                                    {formValues['personalW9_location'] !== "" && <PdfViewer
                                        pdf={process.env.REACT_APP_AZURE_STORAGE_URL + formValues["personalW9_location"]}
                                        name={"personalW9"}
                                        title={"Personal W9"}
                                    />}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                {/*Reviewer Notes*/}
                <div className="card card-success">
                    <div className="card-header" data-card-widget="collapse">
                        <h3 className="card-title">Reviewer Notes</h3>
                        <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse"><i
                                className="fas fa-minus"></i>
                            </button>
                        </div>
                    </div>

                    <div className="card-body" style={{display: 'block'}}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <table className="table table-striped">
                                <tbody>
                                    <tr className="row">
                                        <th className="col-md-2">Notes</th>
                                        <td className="col-md-10">
                                            <textarea {...register("reviewer_notes")} className="form-control" rows="3"
                                                      placeholder=""></textarea>
                                        </td>
                                    </tr>
                                    <tr className="row">
                                        <th className="col-md-2">Message to Applicant</th>
                                        <td className="col-md-10">
                                            <textarea {...register("message_to_applicant")} className="form-control mb-2"
                                                      rows="10" placeholder=""></textarea>
                                            <Button color={"success"} variant={"contained"} className={"mr-1"}
                                                    onClick={() => setMessage('approved')}>Approved Message</Button>
                                            <Button color={"success"} variant={"contained"} className={"mr-1"}
                                                    onClick={() => setMessage('need_more_info')}>Need more info
                                                Message</Button>
                                            <Button color={"success"} variant={"contained"} className={"mr-1"}
                                                    onClick={() => setMessage('reject')}>Reject Message</Button>
                                        </td>
                                    </tr>
                                    <tr className="row">
                                        <th className="col-md-2">Score</th>
                                        <td className="col-md-10">
                                            {applicationScore} / 50
                                        </td>
                                    </tr>
                                    <tr className="row">
                                        <th className="col-md-2">Amount Approved</th>
                                        <td className="col-md-10">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                                <input type="number" {...register("amount_approved")}
                                                       className="form-control"/>
                                            </div>
                                            {errors.amount_approved &&
                                                <p className={"text-danger"}>{errors.amount_approved.message}</p>}
                                        </td>
                                    </tr>
                                    <tr className="row">
                                        <th className="col-md-2"></th>
                                        <td className="col-md-10 text-right">
                                            <Button
                                                type={"submit"}
                                                onClick={() => setApplicationStatus("approved")}
                                                disabled={!isValid}
                                                color={"success"}
                                                variant={"contained"}
                                                className={"mr-2"}
                                            >
                                                Approve
                                            </Button>
                                            <Button
                                                type={"submit"}
                                                onClick={() => setApplicationStatus("need_more_info")}
                                                disabled={!isValid}
                                                color={"warning"}
                                                variant={"contained"}
                                                className={"mr-2"}
                                            >
                                                Need more info
                                            </Button>
                                            <Button
                                                type={"submit"}
                                                onClick={() => setApplicationStatus("rejected")}
                                                disabled={!isValid}
                                                color={"error"}
                                                variant={"contained"}
                                                className={"mr-2"}
                                            >
                                                Reject
                                            </Button>
                                            <Button
                                                type={"submit"}
                                                disabled={!isValid}
                                                color={"secondary"}
                                                variant={"contained"}
                                                className={"mr-2"}
                                            >
                                                Save
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>

                </div>
            </div>
            <DialogModule
                open={confirmOpen}
                title={"Are you sure"}
                // children={confirmLabel}
                setOpen={setConfirmOpen}
                onConfirm={() => {

                }}
                onDecline={() => console.log("declined")}
            />
        </Main>
    );
}

export default EmpowerReview;