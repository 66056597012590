import React, {useEffect, useRef, useState} from 'react';
import Main from "../../modules/main/main";
import {Link, useNavigate, useParams} from "react-router-dom";
import {apiSfa} from "../../services/api/sfa";
import {apiSfaReview} from "../../services/api/sfa-review";
import YouTube from 'react-youtube';
import usePermissions from "../../services/auth/usePermissions";
import PdfViewer from "../../modules/PdfViewer/PdfViewer";
import {useForm, useFormState} from "react-hook-form";
import Button from "@mui/material/Button";
import DialogModule from "../../modules/dialog/dialog";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

function SfaReview(props) {
    const isInitialMount = useRef(true);
    const navigate = useNavigate();
    const {id} = useParams();
    const [createMode, setCreateMode] = useState(true);
    const [userCan] = usePermissions();
    const [loading, setLoading] = useState(true);
    const [formValues, setFormValues] = useState({});
    const [cannedMessages, setCannedMessages] = useState({});
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [applicationStatus, setApplicationStatus] = useState("");
    const validationSchema = Yup.object().shape({
        poi_comment: Yup.string().nullable(),
        lor_comment: Yup.string().nullable(),
        tax1040_comment: Yup.string().nullable(),
        id_comment: Yup.string().nullable(),
        ssn_comment: Yup.string().nullable(),
        gpa_comment: Yup.string().nullable(),
        tuitionInvoice_comment: Yup.string().nullable(),
        w9Form_comment: Yup.string().nullable(),
        amount_approved: Yup.number()
            .required('Please enter the amount approved.')
            .typeError("Please enter the amount approved."),
        reviewer_notes: Yup.string()
            .required("Please enter a note")
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
        defaultValues: {
            sfa_id: Number(id),
            amount_approved: 0
        }
    };
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors, isValid},
        getValues,
        reset
    } = useForm(formOptions);

    let breadcrumbs = [
        {"name": "Dashboard", "active": false},
        {"name": "SFA Application", "active": false},
        {"name": id, "active": true}
    ];

    const fetchData = async (id) => {
        await apiSfa.getSingle(id).then(res => {
            if (res.status === 404) {
                navigate('/')
            }
            setFormValues(res)
            setLoading(false)
        })

        await apiSfaReview.getSingle(id).then(res => {
            if (res.reviewer_notes) {
                // console.log(res)
                setCreateMode(false)
                res.empower_id = Number(id);
                reset(res)

            } else {

            }
            setCannedMessages(res.messages)
        })
    }

    const onSubmit = async (data = getValues()) => {
        data['status'] = applicationStatus
        if (createMode) {
            await apiSfaReview.post(data).then(res => {
                navigate('/applications')
            })
        } else {
            data['_method'] = "PUT";
            await apiSfaReview.patch(data.id, data).then(res => {
                // console.log(res)
                navigate('/applications')
            })
        }
    }

    function setMessage(type, confirmed = false) {
        if (type === "approved") {
            setValue('message_to_applicant', cannedMessages['approved'])
        } else if (type === "need_more_info") {
            setValue('message_to_applicant', cannedMessages['need_more_info'])
        } else if (type === "reject") {
            setValue('message_to_applicant', cannedMessages['rejected'])
        }
    }

    useEffect(() => {
        if (userCan('review.empower')) {
            if (isInitialMount.current) {
                isInitialMount.current = false;
                fetchData(id);
            }
        } else {
            navigate('/')
        }
    }, [])

    const opts = {
        width: '100%',
    };


    return (
        <Main title="SFA Application" breadcrumbs={breadcrumbs} loading={loading}>
            <div className="col-md-12">
                {/*Contact Info*/}
                <div className="card card-success">
                    <div className="card-header" data-card-widget="collapse">
                        <h3 className="card-title">Student Info</h3>
                        <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse"><i
                                className="fas fa-minus"></i>
                            </button>
                        </div>

                    </div>

                    <div className="card-body" style={{display: 'block'}}>
                        <table className="table table-striped">
                            <tbody>
                            <tr className="row">
                                <th className="col-md-4">Student's First name</th>
                                <td className="col-md-8">{formValues['studentFirstName']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Student's Last name</th>
                                <td className="col-md-8">{formValues['studentLastName']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Student's Grade Level K-12th or GPA equivalent</th>
                                <td className="col-md-8">{formValues['gradeLevel']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Parent's First name</th>
                                <td className="col-md-8">{formValues['parentFirstName']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Parent's Last name</th>
                                <td className="col-md-8">{formValues['parentLastName']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Address</th>
                                <td className="col-md-8">
                                {formValues['address']}
                                    <br/>
                                    {formValues['city']}, {formValues['state']} {formValues['zipCode']}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Parent's Phone number</th>
                                <td className="col-md-8">{formValues['phone']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Parent's Email Address</th>
                                <td className="col-md-8">{formValues['email']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Number of People in the Household</th>
                                <td className="col-md-8">{formValues['householdSize']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Total Monthly Gross Income</th>
                                <td className="col-md-8">${formValues['totalMonthlyGrossIncome']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Number of children enrolled in the institution from K-12</th>
                                <td className="col-md-8">{formValues['numberOfChildrenInSchool']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">How much financial aid do you need?</th>
                                <td className="col-md-8">${formValues['aidRequested']}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                {/*School Info*/}
                <div className="card card-success">
                    <div className="card-header" data-card-widget="collapse">
                        <h3 className="card-title">School Info</h3>
                        <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse"><i
                                className="fas fa-minus"></i>
                            </button>
                        </div>

                    </div>

                    <div className="card-body" style={{display: 'block'}}>
                        <table className="table table-striped">
                            <tbody>
                            <tr className="row">
                                <th className="col-md-4">School's Name</th>
                                <td className="col-md-8">{formValues['schoolName']}</td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4"></th>
                                <td className="col-md-8">
                                    {formValues['schoolAddress']}
                                    <br/>
                                    {formValues['schoolAddress2']}
                                    <br/>
                                    {formValues['schoolCity']}, {formValues['schoolState']} {formValues['schoolZipCode']}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">School's Phone number</th>
                                <td className="col-md-8">{formValues['schoolPhone']}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                {/*File Uploads*/}
                <div className="card card-success">
                    <div className="card-header" data-card-widget="collapse">
                        <h3 className="card-title">File Uploads</h3>
                        <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse"><i
                                className="fas fa-minus"></i>
                            </button>
                        </div>
                    </div>

                    <div className="card-body" style={{display: 'block'}}>
                        <table className="table table-striped">
                            <tbody>
                            <tr className="row">
                                <th className="col-md-4">Proof of income</th>
                                <td className="col-md-2">
                                    {!formValues['proofOfIncome_location'] && <strong>No File Was Uploaded</strong>}
                                    {formValues['proofOfIncome_location'] && <PdfViewer
                                        pdf={process.env.REACT_APP_AZURE_STORAGE_URL + formValues['proofOfIncome_location']}
                                        name={"proofOfIncome"}
                                        title={"Proof of income"}
                                    />}
                                </td>
                                <td className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label className="col-sm-2 col-form-label text-right">Comment </label>

                                        <div className="col-sm-10">
                                            <input type="text" {...register("poi_comment")}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    {errors.poi_comment &&
                                        <p className={"text-danger"}>{errors.poi_comment.message}</p>}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Letter of Recommendation from School</th>
                                <td className="col-md-2">
                                    {!formValues['letterOfRecommendationFromSchool_location'] && <strong>No File Was Uploaded</strong>}
                                    {formValues['letterOfRecommendationFromSchool_location'] &&
                                        <PdfViewer
                                            pdf={process.env.REACT_APP_AZURE_STORAGE_URL + formValues["letterOfRecommendationFromSchool_location"]}
                                            name={'letterOfRecommendationFromSchool'}
                                            title={"Letter of Recommendation from School"}
                                        />}
                                </td>
                                <td className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label className="col-sm-2 col-form-label text-right">Comment </label>

                                        <div className="col-sm-10">
                                            <input type="text" {...register("lor_comment")}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    {errors.lor_comment &&
                                        <p className={"text-danger"}>{errors.lor_comment.message}</p>}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Tax Form 1040</th>
                                <td className="col-md-2">
                                    {!formValues['taxForm1040_location'] && <strong>No File Was Uploaded</strong>}
                                    {formValues['taxForm1040_location'] &&
                                        <PdfViewer
                                            pdf={process.env.REACT_APP_AZURE_STORAGE_URL + formValues["taxForm1040_location"]}
                                            name={'taxForm1040'}
                                            title={"Tax Form 1040"}
                                        />}
                                </td>
                                <td className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label className="col-sm-2 col-form-label text-right">Comment </label>

                                        <div className="col-sm-10">
                                            <input type="text" {...register("tax1040_comment")}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    {errors.tax1040_comment &&
                                        <p className={"text-danger"}>{errors.tax1040_comment.message}</p>}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Government-issued ID</th>
                                <td className="col-md-2">
                                    {!formValues['governmentId_location'] && <strong>No File Was Uploaded</strong>}
                                    {formValues['governmentId_location'] && <PdfViewer
                                        pdf={process.env.REACT_APP_AZURE_STORAGE_URL  + formValues["governmentId_location"]}
                                        name={"governmentId"}
                                        title={"Government-issued ID"}
                                    />}
                                </td>
                                <td className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label className="col-sm-2 col-form-label text-right">Comment </label>

                                        <div className="col-sm-10">
                                            <input type="text" {...register("id_comment")}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    {errors.id_comment &&
                                        <p className={"text-danger"}>{errors.id_comment.message}</p>}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Social Security card</th>
                                <td className="col-md-2">
                                    {!formValues['socialSecurityCard_location'] && <strong>No File Was Uploaded</strong>}
                                    {formValues['socialSecurityCard_location'] && <PdfViewer
                                        pdf={process.env.REACT_APP_AZURE_STORAGE_URL + formValues["socialSecurityCard_location"]}
                                        name={"socialSecurityCard"}
                                        title={"Social Security card"}
                                    />}
                                </td>
                                <td className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label className="col-sm-2 col-form-label text-right">Comment </label>

                                        <div className="col-sm-10">
                                            <input type="text" {...register("ssn_comment")}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    {errors.ssn_comment &&
                                        <p className={"text-danger"}>{errors.ssn_comment.message}</p>}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Proof of GPA Requirement</th>
                                <td className="col-md-2">
                                    {!formValues['gpa_location'] && <strong>No File Was Uploaded</strong>}
                                    {formValues['gpa_location'] && <PdfViewer
                                        pdf={process.env.REACT_APP_AZURE_STORAGE_URL + formValues["gpa_location"]}
                                        name={"gpa"}
                                        title={"Proof of GPA Requirement"}
                                    />}
                                </td>
                                <td className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label className="col-sm-2 col-form-label text-right">Comment </label>

                                        <div className="col-sm-10">
                                            <input type="text" {...register("gpa_comment")}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    {errors.gpa_comment &&
                                        <p className={"text-danger"}>{errors.gpa_comment.message}</p>}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">Tuition Invoice</th>
                                <td className="col-md-2">
                                    {!formValues['tuitionInvoice_location'] && <strong>No File Was Uploaded</strong>}
                                    {formValues['tuitionInvoice_location'] && <PdfViewer
                                        pdf={process.env.REACT_APP_AZURE_STORAGE_URL + formValues["tuitionInvoice_location"]}
                                        name={"tuitionInvoice"}
                                        title={"Tuition Invoice"}
                                    />}
                                </td>
                                <td className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label className="col-sm-2 col-form-label text-right">Comment </label>

                                        <div className="col-sm-10">
                                            <input type="text" {...register("tuitionInvoice_comment")}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    {errors.tuitionInvoice_comment &&
                                        <p className={"text-danger"}>{errors.tuitionInvoice_comment.message}</p>}
                                </td>
                            </tr>
                            <tr className="row">
                                <th className="col-md-4">W-9 Form</th>
                                <td className="col-md-2">
                                    {!formValues['w9Form_location'] && <strong>No File Was Uploaded</strong>}
                                    {formValues['w9Form_location'] && <PdfViewer
                                        pdf={process.env.REACT_APP_AZURE_STORAGE_URL + formValues["w9Form_location"]}
                                        name={"w9Form"}
                                        title={"W-9 Form"}
                                    />}
                                </td>
                                <td className="col-md-6">
                                    <div className="form-group row mb-3">
                                        <label className="col-sm-2 col-form-label text-right">Comment </label>

                                        <div className="col-sm-10">
                                            <input type="text" {...register("w9Form_comment")}
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    {errors.w9Form_comment &&
                                        <p className={"text-danger"}>{errors.w9Form_comment.message}</p>}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                {/*Reviewer Notes*/}
                <div className="card card-success">
                    <div className="card-header" data-card-widget="collapse">
                        <h3 className="card-title">Reviewer Notes</h3>
                        <div className="card-tools">
                            <button type="button" className="btn btn-tool" data-card-widget="collapse"><i
                                className="fas fa-minus"></i>
                            </button>
                        </div>
                    </div>

                    <div className="card-body" style={{display: 'block'}}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <table className="table table-striped">
                                <tbody>
                                    <tr className="row">
                                        <th className="col-md-2">Notes <span className="required">*</span></th>
                                        <td className="col-md-10">
                                            <textarea {...register("reviewer_notes")} className="form-control" rows="3"
                                                      placeholder=""></textarea>
                                        </td>
                                    </tr>
                                    <tr className="row">
                                        <th className="col-md-2">Message to Applicant</th>
                                        <td className="col-md-10">
                                            <textarea {...register("message_to_applicant")} className="form-control mb-2"
                                                      rows="10" placeholder=""></textarea>
                                            <Button color={"success"} variant={"contained"} className={"mr-1"}
                                                    onClick={() => setMessage('approved')}>Accepted Application Message</Button>
                                            <Button color={"success"} variant={"contained"} className={"mr-1"}
                                                    onClick={() => setMessage('need_more_info')}>Need more info
                                                Message</Button>
                                            <Button color={"success"} variant={"contained"} className={"mr-1"}
                                                    onClick={() => setMessage('reject')}>Reject Message</Button>
                                        </td>
                                    </tr>
                                    <tr className="row">
                                        <th className="col-md-2">Amount Approved</th>
                                        <td className="col-md-10">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                                <input type="number" {...register("amount_approved")}
                                                       className="form-control"/>
                                            </div>
                                            {
                                                errors.amount_approved &&
                                                <p className={"text-danger"}>{errors.amount_approved.message}</p>
                                            }
                                        </td>
                                    </tr>
                                    <tr className="row">
                                        <th className="col-md-2"></th>
                                        <td className="col-md-10 text-right">
                                            <Button
                                                type={"submit"}
                                                onClick={() => setApplicationStatus("approved")}
                                                disabled={!isValid}
                                                color={"success"}
                                                variant={"contained"}
                                                className={"mr-2"}
                                            >
                                                Accept Application
                                            </Button>
                                            <Button
                                                type={"submit"}
                                                onClick={() => setApplicationStatus("need_more_info")}
                                                disabled={!isValid}
                                                color={"warning"}
                                                variant={"contained"}
                                                className={"mr-2"}
                                            >
                                                Need more info
                                            </Button>
                                            <Button
                                                type={"submit"}
                                                onClick={() => setApplicationStatus("rejected")}
                                                disabled={!isValid}
                                                color={"error"}
                                                variant={"contained"}
                                                className={"mr-2"}
                                            >
                                                Reject
                                            </Button>
                                            <Button
                                                type={"submit"}
                                                disabled={!isValid}
                                                color={"secondary"}
                                                variant={"contained"}
                                                className={"mr-2"}
                                            >
                                                Save
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>

                </div>
            </div>
            <DialogModule
                open={confirmOpen}
                title={"Are you sure"}
                // children={confirmLabel}
                setOpen={setConfirmOpen}
                onConfirm={() => {

                }}
                onDecline={() => console.log("declined")}
            />
        </Main>
    );
}

export default SfaReview;