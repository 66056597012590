import axios from 'axios';
import ApiCore from "../utilities/core";
import { handleResponse, handleError } from '../utilities/response';

const url = 'https://www.googleapis.com/youtube/v3/videos?key=AIzaSyBvJOyoRK1Ek4CqfuJ2HcD_E0or6pbBOHk&part=status';

// plural and single may be used for message logic if needed in the ApiCore class.

const YoutubeApi = new ApiCore({
    post: true,
    getAll: true,
    url: url,
    requireToken: true
});

YoutubeApi.getStatus = (id) => {
    return axios
        .get(`${url}&id=${id}`)
        .then(handleResponse)
        .catch(handleError);
}


export default YoutubeApi;
