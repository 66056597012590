import React from 'react';
import range from "lodash/range";
import {getMonth, getYear} from "date-fns";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import moment from 'moment';



export const years = range(1950, getYear(new Date()) + 1, 1);
export const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

function Datepicker(props) {
    // console.log(props.value)
    let momentDate = "";
    if(props.value){
        momentDate = moment(props.value, 'YYYY-MM-DD').toDate();
    }
    else {
        momentDate = moment().toDate();
    }

    // console.log(momentDate);

    return (
        <DatePicker
            required={props.required}
            renderCustomHeader={({date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled}) => (
                <div style={{margin: 10, display: "flex", justifyContent: "center",}}>
                    <Grid container>
                        <Grid item xs={12} >
                            <FormLabel>{props.label}</FormLabel>
                        </Grid>
                        <Grid item xs={12} >
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} type="button">
                                {"<"}
                            </button>
                            <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} type="button">
                                {">"}
                            </button>
                        </Grid>
                    </Grid>
                </div>
            )}
            selected={momentDate}
            onChange={props.onChange}
            customInput={
                <TextField
                    className="w-100"
                    id={props.name}
                    name={props.name}
                    value={momentDate}
                    label={props.label}
                />
            }
            inline={props.inline}
        />
    );
}

export default Datepicker;