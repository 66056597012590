import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from "./pages/dashboard/dashboard";
import SfaForm from "./pages/sfa/sfa-form";
import EmpowerForm from "./pages/empower/empower-form";
import Login from './pages/login/login'
import Logout from "./pages/logout/logout";
import Register from "./pages/register/register";
import PrivateRoute from "./services/auth/privateRoute";
import VerifyEmail from "./pages/verifyEmail/verifyEmail";
import VerifyAccount from "./pages/verifyAccount/verifyAccount";
import Applications from "./pages/applications/applications";
import ForgotPassword from "./pages/forgot-password/forgot-password";
import PasswordReset from "./pages/password-reset/passwordReset";
import EmpowerReview from "./pages/empower-review/empower-review";
import SfaReview from "./pages/sfa-review/sfa-review";
import Users from "./pages/users/users";
import User from "./pages/user/user";
import Reports from "./pages/reports/reports";
import Settings from "./pages/settings/settings";


function App() {
  return (
    <div className="wrapper">
        <BrowserRouter>
            <Routes>
                {/** Protected Routes */}
                <Route path="/" element={<PrivateRoute />}>
                    <Route path="/" element={<Navigate replace to="/dashboard" />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/applications" element={<Applications />} />
                    <Route path="/reports" element={<Reports />} />
                    <Route path="/sfa-review/:id" element={<SfaReview />} />
                    <Route path="/sfa/:id" element={<SfaForm />} />
                    <Route path="/sfa" element={<SfaForm />}  />
                    <Route path="/empower/:id" element={<EmpowerForm />} />
                    <Route path="/empower-review/:id" element={<EmpowerReview />} />
                    <Route path="/empower" element={<EmpowerForm />} />
                    <Route path="/users/:id" element={<User />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/settings" element={<Settings />} />
                </Route>

                {/** Public Routes */}
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/register/empower" element={<Register type={"empower"} />} />
                <Route path="/register/sfa" element={<Register type={"sfa"} />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/password-reset" element={<PasswordReset />} />
                <Route path="/email/verify/:id" element={<VerifyEmail />} />
                <Route path="/verify-account" element={<VerifyAccount />} />

                {/*<AuthVerify logout={<Logout/>}/>*/}
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
