import React, {useEffect, useState} from 'react';
import PDF from "react-pdf-js";
import DialogModule from "../dialog/dialog";
// Import the main component
import { Viewer } from '@react-pdf-viewer/core'; // install this library
// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// Worker
import { Worker } from '@react-pdf-viewer/core'; // install this library


const PdfViewer = ({pdf, name, title})=> {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [pdfFile, setPdfFile] = useState("");

    useEffect(() => {
        setPdfFile(pdf)
    }, [pdf])

    return(
       <div>
           <button type="button" className="btn btn-default" data-toggle="modal"
                   data-target={"#modal-lg"+name}>
               Click here to view
           </button>
           <div className="modal fade" id={"modal-lg"+name} aria-hidden="true"
                style={{display: "none"}}>
               <div className="modal-dialog modal-lg">
                   <div className="modal-content">
                       <div className="modal-header">
                           <h4 className="modal-title">{title}</h4>
                           <button type="button" className="close" data-dismiss="modal"
                                   aria-label="Close">
                               <span aria-hidden="true">×</span>
                           </button>
                       </div>
                       <div className="modal-body">
                           <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js">
                               <Viewer fileUrl={pdfFile}
                                       plugins={[defaultLayoutPluginInstance]} />
                           </Worker>
                       </div>
                       <div className="modal-footer">
                           <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                       </div>
                   </div>
               </div>
           </div>
       </div>
    )};

export default PdfViewer;