import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import LogoutApi from "../../services/api/logout";

function Logout(props) {
    const navigate = useNavigate();
    LogoutApi.post().then((data) => {
        // console.log(data)

    }).finally(() => {
        localStorage.removeItem('token')
        navigate('/login')
    })

    return (
        <div className="verify-page">
            <div className="verify-box">
                <div className="card">
                    <div className="card-header text-center">
                        <h5 className="card-title">Please wait, we are logging you out! See you back soon!</h5>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Logout;