import React, {useState} from 'react';
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import useUser from "../../../services/auth/useUser";
import {apiUsers} from "../../../services/api/users";
import {useNavigate} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SettingsTab({isActive, allRoles, setReload, user}) {
    const currentUser = useUser();
    // const [user, setUser] = useState(user);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Please enter a name.'),
        email: Yup.string().required('Please enter a email.'),
        password: Yup.string().optional(),
        passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Password must match.'),
        role: Yup.string().required('Please select a role'),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
        defaultValues: {
            name: user['name'],
            email: user['email'],
            role: user.roles[0].id,
        }
    };
    const {
        register,
        handleSubmit,
        reset,
        formState
    } = useForm(formOptions);
    const { errors, isValid } = formState;

    function onSubmit(data) {
        data['_method'] = 'patch';
        apiUsers.patch(user.id, data).then((res) => {
            if(res.status) {
                setError(res.message);

            }
            else {
                toast('User Saved Successfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: 'success'
                });
                // setSuccessMessages('User Saved Successfully')
                setReload(true);
                user = res.user;
                reset({
                    name: res.user['name'],
                    email: res.user['email'],
                    role: res.user.roles[0].id,
                    password: '',
                    passwordConfirm: ''
                });
            }
        });
    }

    return (
        <div className={`tab-pane ${isActive ? 'active' : ''}`}>
            {error && <div className="alert alert-danger">
                <h5><i className="icon fas fa-ban"></i> Error!</h5>
                {error}
            </div>}
            <ToastContainer />
            <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                    <label htmlFor="inputName" className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            name="name"
                            {...register('name')}
                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            placeholder="Name"
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="inputEmail" className="col-sm-2 col-form-label">Email</label>
                    <div className="col-sm-10">
                        <input
                            type="email"
                            name="email"
                            {...register('email')}
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            placeholder="Email"
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="inputExperience" className="col-sm-2 col-form-label">Role</label>
                    <div className="col-sm-10">
                        {/*<textarea className="form-control" id="inputExperience" placeholder="Role"></textarea>*/}
                        <select
                            name="role"
                            className={`form-control ${errors.role ? 'is-invalid' : ''}`}
                            {...register('role')}
                            disabled={currentUser.id === user.id}
                        >
                            {
                                allRoles.map((role) => (
                                    <option key={role['id']} value={role['id']}>{role['name']}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="inputSkills" className="col-sm-2 col-form-label">Password</label>
                    <div className="col-sm-10">
                        <input
                            type="password"
                            name="password"
                            {...register('password')}
                            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                            placeholder="Password"
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="inputSkills" className="col-sm-2 col-form-label">Confirm Password</label>
                    <div className="col-sm-10">
                        <input
                            type="password"
                            name="passwordConfirm"
                            {...register('passwordConfirm')}
                            className={`form-control ${errors.passwordConfirm ? 'is-invalid' : ''}`}
                            placeholder="Confirm Password"
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="offset-sm-2 col-sm-10">
                        <button type="submit" className="btn btn-primary" disabled={!isValid}>Save</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SettingsTab;