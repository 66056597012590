import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import ReactLoading from 'react-loading';
import {useForm} from "react-hook-form";
import { RegisterApi } from "../../services/api/register";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';


function Register(props) {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(undefined);
    const [registeredMsg, setRegisteredMsg] = useState(undefined);
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Full Name is required'),
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('Password is required'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
        acceptTerms: Yup.bool()
            .oneOf([true], 'Accept Ts & Cs is required'),
        type: Yup.string()
            .required('Please select your type'),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        defaultValues: {
            // name: "Qazi Osama",
            // // email: "qazi91@gmail.com",
            // password: "test1234",
            // password_confirmation: "test1234",
            // acceptTerms: true,
            type: props.type !== undefined ? props.type === "sfa" ? "parent" : "teacher" : ""
        },
        mode: 'onChange'
    };
    const {register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isValid } = formState;
    // console.log(registeredMsg)

    const onRegisterClick = (formValues) =>{
        // display form data on success
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
        // return false;
        //     setErrorMsg("Register is not implemented yet");
        setRegisteredMsg(undefined)
        setErrorMsg(undefined)
        setLoading(true);

        // console.log(formValues)

        RegisterApi.post(formValues).then(res => {
            // console.log(res);
            // console.log(res.message);
            setLoading(false)
            if(res.status === 409){
                setErrorMsg(prev=> res.data.message)
            }
            // else{
            //     localStorage.setItem("token", res.access_token)
            //     setToken(res.access_token)
            // }
            // else{
            localStorage.setItem('registered', res.message);
            navigate('/login')
            // setRegisteredMsg(prev=> res.message)
            // }
            // reset()
        });
    }

    return (
        <div className="register-page">
            <div className={registeredMsg !== undefined ? "alert alert-success alert-dismissible": "d-none"}>
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                <h5><i className="icon fas fa-check"></i> Success!</h5>
                {registeredMsg}
            </div>

            <div className={errorMsg !== undefined ? "alert alert-danger alert-dismissible": "d-none"}>
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                <h5><i className="icon fas fa-check"></i> Error!</h5>
                {errorMsg}
            </div>
            <div className="register-box">
                <div className="card card-outline card-primary ">

                    <div className={loading ? "overlay" : "overlay d-none"}>
                        <ReactLoading type="spin" color="black" width={'10%'} height={'10%'} />
                    </div>

                    <div className="card-header text-center">
                        <Link to={"/register"} className="h1">{process.env.REACT_APP_NAME}</Link>
                    </div>
                    <div className="card-body">
                        <p className="register-box-msg">Register a new account</p>
                        <form onSubmit={handleSubmit(onRegisterClick)}>
                            <div className="input-group mb-3">
                                <input type="text" className={`form-control ${errors.name ? 'is-invalid' : ''}`} placeholder="Full name" {...register('name')} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-user" />
                                    </div>
                                </div>
                                <div className="invalid-feedback">{errors.name?.message}</div>
                            </div>

                            <div className="input-group mb-3">
                                <input name="email" type="text" {...register('email')} placeholder="Email Address" className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                                <div className="invalid-feedback">{errors.email?.message}</div>
                            </div>

                            <div className="input-group mb-3">
                                <input type="password" name="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} placeholder="Password" autoComplete="off"/>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"/>
                                    </div>
                                </div>
                                <div className="invalid-feedback">{errors.password?.message}</div>
                            </div>

                            <div className="input-group mb-3">
                                <input name="password_confirmation" type="password" placeholder="Retype Password" {...register('password_confirmation')} className={`form-control ${errors.password_confirmation ? 'is-invalid' : ''}`} autoComplete="off"/>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"/>
                                    </div>
                                </div>
                                <div className="invalid-feedback">{errors.password_confirmation?.message}</div>
                            </div>

                            <div className={props.type !== undefined ? "d-none" : "input-group mb-3"}>
                                <select name="type" {...register('type')} className={`form-control ${errors.type ? 'is-invalid' : ''}`} >
                                    <option value="">Are you are Parent or Teacher?</option>
                                    <option value="parent">Parent</option>
                                    <option value="teacher">Teacher</option>
                                </select>
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-cog"/>
                                    </div>
                                </div>
                                <div className="invalid-feedback">{errors.type?.message}</div>
                            </div>

                            <div className="row">
                                {/*<div className="col-12 text-center">*/}
                                {/*    <div className="form-check">*/}
                                {/*        <input name="acceptTerms" type="checkbox" {...register('acceptTerms')} id="acceptTerms" className={`form-check-input ${errors.acceptTerms ? 'is-invalid' : ''}`} />*/}
                                {/*        <label htmlFor="acceptTerms">*/}
                                {/*            I agree to the <a href='#'>terms</a>*/}
                                {/*        </label>*/}
                                {/*        <div className="invalid-feedback">{errors.acceptTerms?.message}</div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*/.col*/}
                                <div className="col-12">
                                    <button disabled={!isValid} type={"submit"} className="btn btn-primary btn-block">Register</button>
                                </div>
                                {/*/.col*/}
                            </div>
                        </form>

                        <p className="text-center mt-3 mb-0">
                            <Link to={"/login"} className="text-center">I already have an account</Link>
                        </p>
                    </div>
                    {/*/.card-body*/}
                </div>
                {/*/.card*/}
            </div>
        </div>
    );
}

export default Register;