import ApiCore from "../utilities/core";

const url = 'auth/email/resend';

// plural and single may be used for message logic if needed in the ApiCore class.

export const ResendVerificationEmailApi = new ApiCore({
    getAll: true,
    getSingle: false,
    post: false,
    put: false,
    patch: false,
    delete: false,
    url: url,
    requireToken: true
});
